import { mapActions, mapGetters } from "vuex";
import * as types from "../../../../store/types";
import transactionsService from "../../../../services/transactionsService";
import userInfoService from "../../../../services/userInfoService";
import currenciesService from "../../../../services/currenciesService";
import orderList from "../../../../components/orderList/index.vue";

export default {
    name: "transactions",
    components: {
        orderList,
    },
    props: [],
    data() {
        return {
            requestInterval: "",
            types: "",
            statuses: "",
            refIdSearch: null,
            orderIdSearch: null,
            statusText: this.$i18n.t("panel.merchant.transactions.search.all"),
            status: null,
            typeText: this.$i18n.t("panel.merchant.transactions.search.all"),
            type: null,
            currentPage: 1,
        };
    },
    computed: {
        ...mapGetters({
            isTransActionsLoaded: types.GET_IS_TRANSACTIONS_LOADED,
            transactions: types.GET_TRANSACTIONS,
        }),
        rows() {
            return this.items.length;
        },
    },
    mounted() {},
    created() {
        this.getInfoData();
        this.getTypesFunc();
        this.getStatusFunc();
        this.getTransactionsData();
        this.getCurrenciesFunc();
    },
    methods: {
        ...mapActions({
            userInfoAction: types.ACTION_USER_INFO,
            transactionsAction: types.ACTION_TRANSACTIONS,
            currenciesAction: types.ACTION_CURRENCIES,
        }),
        getStatusFunc() {
            transactionsService.getMerchantOrdersStatus().then((response) => {
                this.statuses = response.data;
            });
        },
        getTypesFunc() {
            transactionsService.getMerchantOrdersTypes().then((response) => {
                this.types = response.data;
            });
        },
        changeStatus(status, text) {
            this.status = status;
            this.statusText = text;
        },
        changeType(type, text) {
            this.type = type;
            this.typeText = text;
        },
        submitFilterTransactions() {
            if (this.refIdSearch === "") {
                this.refIdSearch = null;
            }
            if (this.orderIdSearch === "") {
                this.orderIdSearch = null;
            }
            transactionsService
                .getTransactions(
                    localStorage.getItem("bitrahAccessToken"),
                    0,
                    this.status,
                    this.type,
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.currentPage = 1;
                    this.transactionsAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getCurrenciesFunc() {
            currenciesService
                .getCurrencies()
                .then((response) => {
                    this.currenciesAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getInfoData() {
            userInfoService
                .getUserInfo()
                .then((response) => {
                    this.userInfoAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                })
                .finally(() => (this.loading = false));
        },
        getPaginationData(id) {
            transactionsService
                .getTransactions(
                    localStorage.getItem("bitrahAccessToken"),
                    id - 1,
                    this.status,
                    this.type
                )
                .then((response) => {
                    this.transactionsAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getTransactionsData() {
            transactionsService
                .getTransactions(localStorage.getItem("bitrahAccessToken"), 0)
                .then((response) => {
                    this.transactionsAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
    },
};
